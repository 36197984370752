import React from "react";
import ReactPlayer from "react-player/lazy";
import Assets from "../../../constant/Assets";

const VideoPlayer = () => {
  return (
    <div className="container mt-8 bg-[#05101a28] p-4 rounded-lg md:w-[70%] md:h-[740px] ml-auto mr-auto">
      <ReactPlayer
        url="https://grupa-media.s3.us-west-2.amazonaws.com/grupa-draft-video.mp4"
        height={"100%"}
        width={"100%"}
        light={
          <img
            src={Assets.IMAGE.ThumbnailPngL}
            alt="Thumbnail"
            className="rounded-md"
          />
        }
        controls={true}
        playIcon={
          <img
            src={Assets.IMAGE.Play.default}
            className="mt-auto mb-auto mr-auto ml-auto absolute "
            alt="play icon"
          />
        }
      />
    </div>
  );
};

export default VideoPlayer;

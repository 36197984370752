import React, { useState, useEffect } from "react";

import Assets from "../../constant/Assets";
//import Config from "../../constant/Config";
import VideoPlayer from "./components/VideoPlayer";

const LandingScreen = () => {
  const [isError, setIsError] = useState(false);
  const [isErrorMsg, setIsErrorMsg] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  const [emailValue, setEmailValue] = useState("");

  //const [sendGridValidate, setSendGridValidate] = useState([]);
  //const [isLoading, setIsLoading] = useState(false);
  //const [isEmailValidated, setIsEmailValidated] = useState(false);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  // Function to collect data
  /* const getApiData = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: emailValue }),
    };
    setIsLoading(true);

    await fetch(
      `${
        Config.LANDING.BaseUrl +
        Config.LANDING.Prefix +
        Config.LANDING.ValidateEmail
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setSendGridValidate(data);
        setIsLoading(false);

        console.log(data);
      });
  }; */

  const submitHandler = () => {
    if (emailValue === "") {
      setIsErrorMsg("Email field can not be empty");
      setIsError(true);
    } else if (!isValidEmail(emailValue)) {
      setIsErrorMsg("Must be a valid email address");
      setIsError(true);
    } /* else if (!isEmailValidated) {
      getApiData();
    } */ else {
      setIsError(false);
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      const timer = setTimeout(() => {
        document.location.href =
          "https://form.typeform.com/to/oTgxWrRw#email=" + emailValue;
      }, 3000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmit]);
  return (
    <div
      style={{ backgroundImage: `url(${Assets.IMAGE.background.default})` }}
      className="container-fluid w-full  bg-repeat-x sm:px-5  content-start border-0 border-red-600  items-center justify-center "
    >
      <div
        //style={{ backgroundImage: `url(${Assets.IMAGE.topBG.default})` }}
        className="w-full border-0 border-black bg-center  items-center justify-center   pt-[60px] px-5	"
      >
        <img
          src={Assets.IMAGE.Logo.default}
          className="ml-auto mr-auto xs:w-[109px] sm:w-[109px] md:w-[194px]"
          alt="logo"
        />
        <div className="sm:text-[40px] xs:text-[32px] md:text-[65px] font-bold  mt-5 text-[#102232]">
          Build with No Limits
        </div>
        <div className="mt-1 text-[##324B62] md:text-[20px]  border-0 border-red-50 ml-auto mr-auto sm:w-[100%] md:w-[650px] ">
          Make your startup a reality with the power of AI. Bring your ideas to
          life, faster and easier than ever before.
        </div>

        {isError && (
          <div className=" sm:w-[100%] md:w-[500px] ml-auto mr-auto  mt-10">
            <div
              class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong class="font-bold">ERROR</strong>
              <span class="block sm:inline">{isErrorMsg}</span>
              <span
                class="absolute top-0 bottom-0 right-0 px-4 py-3"
                onClick={() => setIsError(false)}
              >
                <svg
                  class="fill-current h-6 w-6 text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          </div>
        )}

        <div className="flex xs:flex-col flex-row  gap-3 justify-evenly sm:w-[100%] md:w-[500px] ml-auto mr-auto  mt-6">
          <div className="w-full mt-2">
            <input
              onChange={(e) => setEmailValue(e.target.value)}
              value={emailValue}
              disabled={isSubmit}
              placeholder="Enter your email"
              className="mt-1 h-12 p-4 
                    block
                    w-full
                    rounded-md
                    border-[1px]
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div className="w-[30%] xs:w-full sm:w-full  mt-2">
            <button
              onClick={submitHandler}
              class="bg-[#0781F2] text-[14px] hover:bg-sky-700 text-white  font-bold h-[47px] mt-1 rounded-md w-[140px] sm:w-full xs:w-full"
            >
              {isSubmit /* || isLoading */ ? (
                <div
                  class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              ) : (
                "Get Early Access"
              )}
            </button>
          </div>
        </div>

        <img
          src={Assets.IMAGE.Points.default}
          className="ml-auto mr-auto mt-24"
          alt="points"
        />
      </div>

      <div className="px-5 md:mt-20 xs:mt-5 sm:mt-5">
        <VideoPlayer />
      </div>

      <div className="m-12 md:p-10 sm:p-0 xs:p-0 pb-0">
        <div className="w-[100%] border-t-[0.6px] border-t-[#A2B9CD]"></div>

        <div className="w-[100%] flex flex-grow gap-8 pt-5 ">
          <div className="justify-end  w-full text-right text-[13px] text-[#5F7B95]">
            Terms of Service
          </div>
          <div className="justify-start w-full text-left text-[13px] text-[#5F7B95]">
            Privacy Policy
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingScreen;

const Assets = {
  IMAGE: {
    Logo: require("../assets/logo.svg"),
    topBG: require("../assets/topBG.svg"),
    Points: require("../assets/points.svg"),
    Play: require("../assets/play.svg"),
    Thumbnail: require("../assets/thumbnail.svg"),
    ThumbnailPng: require("../assets/thumbnail.png"),
    ThumbnailPngL: require("../assets/thumbnailL.png"),
    pattern: require("../assets/pattern.svg"),
    background: require("../assets/background.svg"),

  },
};
export default Assets;

import "./assets/App.css";
import LandingScreen from "./screen/landing";

function App() {
  return (
    <div className="App">
      <LandingScreen />
    </div>
  );
}

export default App;
